import React, { useState, useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  useForm,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AutoComplete from 'react-google-autocomplete';
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';
import { datadogRum } from '@datadog/browser-rum';
import '../styles/expressFormDynamic.css';
import { env } from 'process';

enum States {
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  TAS = 'TAS',
  NT = 'NT',
  ACT = 'ACT',
}

enum EmploymentStatus {
  FullTime = 'Full Time',
  PartTime = 'Part Time',
  Casual = 'Casual',
  Contract = 'Contract',
  SelfEmployed = 'Self Employed',
  Unemployed = 'Unemployed',
}

enum EmploymentType {
  Employee = 'Employee',
  BusinessOwner = 'Business Owner Or Self Employed',
  Unemployed = 'Unemployed',
}

enum BusinessStructure {
  SoleTrader = 'Sole Trader',
  Partnership = 'Partnership',
  Company = 'Company',
  Trust = 'Trust',
}

enum LoanPurpose {
  CarLoan = 'Car Loan',
  Motorcycle = 'Motorcycle Loan',
  Caravan = 'Caravan Loan',
  Boat = 'Boat Loan',
  PersonalLoanSec = 'Personal Loan (Secured)',
  PersonalLoanUnsec = 'Personal Loan (Unsecured)',
  HorseFloat = 'Horse Float Loan',
  Tractor = 'Tractor Loan',
  OtherAsset = 'Other Asset (Tractor, Trailer etc.)',
}

enum creditRating {
  Excellent = 'Excellent',
  Average = 'Average',
  Fair = 'Fair',
  Poor = 'Poor',
  Unsure = 'Unsure'
}

type Inputs = {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  age: number;
  driversLicenceNumber: string;
  licenceIssuingState: States;
  phoneNumber: string;
  email: string;
  livingSituation: string;
  residentialStatus: string;
  address: string;
  atAddressYears: number | string;
  atAddressMonths: number;
  postcode: string;
  suburb: string;
  state: States;
  timeAtAddressYears: number;
  timeAtAddressMonths: number;
  employmentStatus: EmploymentStatus;
  employmentType: EmploymentType;
  occupation: string;
  employerName: string;
  atJobYears: number;
  atJobMonths: number;
  incomeFrequency: string;
  incomeAfterTax: number;
  afterTaxSalaryYearly: number;
  loanPurpose: LoanPurpose;
  loanAmount: number;
  loanTermMonths: number;
  loanTermYears: number;
  detailsOfEnquiry: string;
  acceptTerms: boolean;
  acceptPicklesTerms: boolean;
  currentlyLiving: boolean;
  currentlyWorking: boolean;
  businessName: string;
  businessStructure: BusinessStructure;
  abnRegistrationPeriod: string;
  registeredGST: boolean;
  customerCreditRatingDepiction: creditRating;
  dependents: number;
  residencyStatus: 'Australian Citizen' | 'Permanent Resident' | 'Visa 457' | 'Visa Other' | 'Non Resident'
  relationshipStatus: 'Married' | 'Defacto' | 'Single' | 'Other';
};

type Vehicle = {
  StockNumber: string;
  Make: string;
  Model: string;
  Year: number;
  Odometer: number;
  FuelType: string;
  WOVR: string;
  VIN: string;
  FixedPrice: number;
  BuildDate: string;
};

interface LeadMarketContactTypeOther {
  Contact_Other: {
    Contact_Type: string;
    Contact_Value: string;
    V_Contact_Type: string;
    V_Contact_Value: string;
  };
}

interface LeadMarketAddressType {
  Address: {
    Address_Line_1: string;
    Address_Line_2: string;
    State: States;
    Postcode: string;
    Suburb: string;
    Is_Current: boolean;
  }
}

interface LeadMarketIdentificationType {
  Date_Expiry: string;
  Date_Issue: string;
  Document_ID: string;
  Issuer: string;
  Type: 'Drivers License' | 'Medicare Card' | 'Passport' | 'Student Card'
}

interface LeadMarketApplicant {
  Credit_Rating: {
    Self_Reported_Credit_Rating: 'excellent' | 'very_good' | 'good' | 'fair' | 'low'
  }
  Person_Applicant: {
    First_Name: string
    Last_Name: string
    Middle_Names: string
    Date_Of_Birth: string
    Dependents: number
    Citizenship_Status: 'australian_citizen' | 'permanent_resident' | 'new_zealand_resident' | 'other' | 'working_visa' | 'student_visa'
    Marital_Status: 'Married' | 'Defacto' | 'Single' | 'Separated' | 'Divorced' | 'Widowed'
    Gender: 'M' | 'F' | 'Other'
    Contact_Methods: {
      Contact_Method: (LeadMarketContactTypeOther | LeadMarketAddressType)[]
    }
    Proof_Of_Identity: {
      Identification: LeadMarketIdentificationType[]
    }
  }



}

interface LeadMarketApplication {
  Amount: number
  Application_Date: string
  Fees: number
  Interest_Rate: number
  Reason: string
  Priority_Feature: string
  Type: string
  Repayment_Amount: number
  Repayment_Count: number
  Repayment_Cycle: 'Weekly' | 'Fortnightly' | 'Monthly'
  Security: {
    Asset_Type: string
  }
  Description: string
}

interface LeadMarketBuyerData {
  Lead_ID: string
  CS_App_ID: number
}

interface LeadMarketCustomData {
  Accepted_Terms_And_Conditions: boolean
  Clicked_Submit: boolean
}

interface LeadMarketLeadInterface {
  Applicant: LeadMarketApplicant
  Application: LeadMarketApplication
  Buyer_Data: LeadMarketBuyerData
  Custom_Data: LeadMarketCustomData
}


declare const window: Window & { dataLayer: Record<string, unknown>[] };


const ExpressForm = () => {
  const [hasClickedSubmit, setHasClickedSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formSubmittedError, setFormSubmittedError] = useState<boolean>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [leadMarketLead, setLeadMarketLead] = useState<LeadMarketLeadInterface | undefined>();
  const [leadMarketAdditionalInformation, setLeadMarketAdditionalInformation] = useState<string>('');
  const environment = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT || '';
  const apiEndpoint = environment === 'production' ? process.env.REACT_APP_API_URL || '' : process.env.REACT_APP_STAGING_API_URL || '';
  const apiKey = environment === 'production' ? process.env.REACT_APP_API_KEY || '' : process.env.REACT_APP_STAGING_API_KEY || '';
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { acceptTerms: false } });
  const methods = useForm<Inputs>();
  // ** Personal Watchers
  const firstName = getValues('firstName');
  const lastName = getValues('lastName');
  const email = getValues('email');
  const phoneNumber = getValues('phoneNumber');
  const loanAmount = getValues('loanAmount');
  const state = getValues('state');

  const partnerBrandPrimary = process.env.REACT_APP_PARTNER_COLOUR_PRIMARY;
  const partnerBrandSecondary = process.env.REACT_APP_PARTNER_COLOUR_SECONDARY;
  const partnerBrandError = process.env.REACT_APP_PARTNER_COLOUR_ERROR;

  const searchParams = new URLSearchParams(window.location.search);
  const lmToken = searchParams.get('token')

  // ** Employment Watchers

  function isTouched(inputField: any) {
    return Boolean(inputField);
  }

  async function callLeadMarket(t: string) {
    if (!t) {
      datadogLogs.logger.error('LeadMarket Token not found');
      return;
    }
    const endpoint = `https://api.c3p.carclarity.com.au/marketing/lm/details?btok=${t}`;
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      datadogLogs.logger.info(`LeadMarket Lead Data obtained for token`, data);

      if (data.Data.Lead) {
        console.log(data.Data.Lead)
        datadogLogs.logger.info(`LeadMarket Lead Data obtained for token`, data.Data.Lead);
        setLeadMarketLead(data.Data.Lead);
      }

    } catch (error: any) {
      datadogLogs.logger.error('Platform API Error - unable to get leadmarket lead', error);
    }

  }



  useEffect(() => {
    if (lmToken) {
      callLeadMarket(lmToken);
    }
  }, [lmToken]);

  useEffect(() => {
    if (formSubmittedError) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setFormSubmittedError(false);
      }, 5000);
    }

    if (Object.keys(errors).length > 0) {
      const firstError = Object.keys(errors)[0] as keyof typeof errors;
      console.log(firstError as string);
      setFocus(firstError as any);
    }
    const qspBusinessName = searchParams.get('businessname');
    const qspFirstName = searchParams.get('firstname');
    const qspLastName = searchParams.get('lastname');
    const qspEmail = searchParams.get('emailaddress');
    const qspPhone = searchParams.get('phoneno');

    if (qspBusinessName) {
      setValue('businessName', qspBusinessName);
    }
    if (qspFirstName) {
      setValue('firstName', qspFirstName);
    }
    if (qspLastName) {
      setValue('lastName', qspLastName);
    }
    if (qspEmail) {
      setValue('email', qspEmail);
    }
    if (qspPhone) {
      setValue('phoneNumber', qspPhone);
    }





    // businessname=*|BUSINESS_N|*&firstname=*|FNAME|*&lastname= *|LNAME|*&emailaddress=*|EMAIL|*&phoneno=*|PER_PHONE|*
  }, [errors, setFocus, formSubmittedError, setValue, searchParams]);

  // ** useEffect hook for when LeadMarketLead is updated - Set form values
  useEffect(() => {

    if (leadMarketLead && Object.keys(leadMarketLead).length > 0) {

      // ** set form values
      setValue('firstName', leadMarketLead.Applicant.Person_Applicant.First_Name);
      setValue('lastName', leadMarketLead.Applicant.Person_Applicant.Last_Name);
      if (leadMarketLead.Applicant.Person_Applicant.Dependents !== null) {
        setValue('dependents', leadMarketLead.Applicant.Person_Applicant.Dependents)
      }
      if (leadMarketLead.Applicant.Person_Applicant.Marital_Status !== null) {
        switch (leadMarketLead.Applicant.Person_Applicant.Marital_Status) {
          case 'Married':
            setValue('relationshipStatus', 'Married')
            break;
          case 'Defacto':
            setValue('relationshipStatus', 'Defacto')
            break;
          case 'Single':
            setValue('relationshipStatus', 'Single')
            break;
          default:
            setValue('relationshipStatus', 'Other')
            break;
        }
      }
      if (leadMarketLead.Applicant.Person_Applicant.Citizenship_Status !== null) {
        switch (leadMarketLead.Applicant.Person_Applicant.Citizenship_Status) {
          case 'australian_citizen':
            setValue('residencyStatus', 'Australian Citizen')
            break;
          case 'permanent_resident':
            setValue('residencyStatus', 'Permanent Resident')
            break;
          case 'new_zealand_resident':
            setValue('residencyStatus', 'Permanent Resident')
            break;
          case 'working_visa':
            setValue('residencyStatus', 'Visa 457')
            break;
          case 'student_visa':
            setValue('residencyStatus', 'Visa Other')
            break;
          default:
            setValue('residencyStatus', 'Australian Citizen')
            break;
        }
      }
      // ** Set contact points
      for (const contact in leadMarketLead.Applicant.Person_Applicant.Contact_Methods.Contact_Method) {
        const contactMethod = leadMarketLead.Applicant.Person_Applicant.Contact_Methods.Contact_Method[contact];
        if ('Address' in contactMethod && contactMethod.Address !== null) {
          //  ** Address
          setValue('state', contactMethod.Address.State);
        } else if ('Contact_Other' in contactMethod) {
          // ** Other Contacts (EMAIL, PHONE, MOB)
          if (contactMethod.Contact_Other.Contact_Type === 'EMAIL') {
            setValue('email', contactMethod.Contact_Other.Contact_Value);
          } else if (contactMethod.Contact_Other.Contact_Type === 'MOB') {
            if (contactMethod.Contact_Other.V_Contact_Value) {
              setValue('phoneNumber', contactMethod.Contact_Other.V_Contact_Value)
            } else {
              // ** remove the leading '+' from the phone number
              setValue('phoneNumber', contactMethod.Contact_Other.Contact_Value.substring(1));
            }
          }
        }
      }

      if (leadMarketLead.Applicant.hasOwnProperty('Credit_Rating')) {
        switch (leadMarketLead.Applicant.Credit_Rating.Self_Reported_Credit_Rating) {
          case 'excellent':
            setValue('customerCreditRatingDepiction', 'Excellent' as creditRating)
            break;
          case 'very_good':
            setValue('customerCreditRatingDepiction', 'Excellent' as creditRating)
            break;

          case 'good':
            setValue('customerCreditRatingDepiction', 'Average' as creditRating)
            break;

          case 'fair':
            setValue('customerCreditRatingDepiction', 'Fair' as creditRating)
            break;

          case 'low':
            setValue('customerCreditRatingDepiction', 'Poor' as creditRating)
            break;

          default:
            setValue('customerCreditRatingDepiction', 'Unsure' as creditRating)
            break;
        }
      }

      // ** Build Application and Additional Information
      if (leadMarketLead.Application !== null) {

        leadMarketLead.Application.hasOwnProperty('Amount') ? setValue('loanAmount', Math.floor(leadMarketLead.Application.Amount)) : setValue('loanAmount', 0);

        const addInfo = {
          "Reason For Finance": leadMarketLead.Application.hasOwnProperty('Reason') ? leadMarketLead.Application.Reason : null,
          "Priority Feature": leadMarketLead.Application.hasOwnProperty('Priority_Feature') ? leadMarketLead.Application.Priority_Feature : null,
          "Initial Application Date": leadMarketLead.Application.hasOwnProperty('Application_Date') ? leadMarketLead.Application.Application_Date : null,
          "Application Description": leadMarketLead.Application.hasOwnProperty('Description') ? leadMarketLead.Application.Description : null,
          "Finance Type": leadMarketLead.Application.hasOwnProperty('Type') ? leadMarketLead.Application.Type : null,
        }
        setLeadMarketAdditionalInformation(JSON.stringify(addInfo));

        // ** get loan term in years from application.Repayment_Frequency and application.Repayment_Count

        switch (leadMarketLead.Application.Repayment_Cycle) {
          case 'Weekly':
            setValue('loanTermYears', leadMarketLead.Application.Repayment_Count / 52);
            break;
          case 'Fortnightly':
            setValue('loanTermYears', leadMarketLead.Application.Repayment_Count / 26);
            break;
          case 'Monthly':
            setValue('loanTermYears', leadMarketLead.Application.Repayment_Count / 12);
            break;

          default:
            setValue('loanTermYears', 5);
            break;
        }
      }


      switch (leadMarketLead.Applicant.Person_Applicant.Gender) {
        case 'M':
          setValue('gender', 'Male')
          break;
        case 'F':
          setValue('gender', 'Female')
          break;
        case 'Other':
          setValue('gender', 'Other')
          break;
        default:
          setValue('gender', 'Other')
          break;
      }
    }

  }, [leadMarketLead]);

  const onError = (errors: any, e: any) => {
    setHasClickedSubmit(true);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setHasClickedSubmit(true);
    datadogRum.addAction('Form Submitted');
    window.dataLayer.push({
      event: 'formSubmitted',
    });
    // ** change phone numbers prefixed with +61 or 61 to 0
    if (data.phoneNumber.startsWith('61')) {
      data.phoneNumber = data.phoneNumber.replace('61', '0');
    }
    if (data.phoneNumber.startsWith('+61')) {
      data.phoneNumber = data.phoneNumber.replace('+61', '0');
    }

    datadogRum.setUser({
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      phone: data.phoneNumber,
    });

    const turboReferralBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.phoneNumber,
      email: data.email,
      gender: data.gender,
      dateOfBirth: leadMarketLead?.Applicant.Person_Applicant.hasOwnProperty('Date_Of_Birth') ? leadMarketLead.Applicant.Person_Applicant.Date_Of_Birth : '1970-01-01',
      urgency: 'ASAP',
      purchasePrice: data.loanAmount || 0,
      certaintyStage: 'Exact',
      requestedLoanTermYears: data.loanTermYears || 7,
      currentWorkStatus: 'Employee',
      residency: {
        state: data.state || 'NSW',
        residencyStatus: data.residencyStatus || 'Australian Citizen',
      },
      relationshipStatus: data.relationshipStatus || 'Single',
      numberOfDependants: data.dependents || 0,
      customerCreditRatingDepiction: data.customerCreditRatingDepiction || 'Excellent',
      partnerLeadId: JSON.stringify(leadMarketLead?.Buyer_Data.Lead_ID) || 'No Lead ID',
      additionalInformation: leadMarketAdditionalInformation,
      csAppId: leadMarketLead?.Buyer_Data.CS_App_ID
    };




    setIsLoading(true);
    console.log(turboReferralBody)
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-PARTNER-ID': process.env.REACT_APP_PARTNER_ID || '',
        'X-API-KEY': apiKey,
      },
      body: JSON.stringify(turboReferralBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          // ** Send error to GTM dataLayer

          window.dataLayer.push({
            event: 'formSubmitError',
            error: data.error,
          });
          // ** Send error to datadog RUM
          datadogRum.addError(data.error);
          setIsLoading(false);
          setApiErrorMessage(data.error);
          setFormSubmittedError(true);
        } else {
          // ** add created lead data to Datadog
          datadogRum.addAction('Lead Submit Success', { data });
          // ** Send success to GTM dataLayer
          window.dataLayer.push({
            event: 'formSuccessfullySubmitted',
          });

          try {
            fetch('https://api.c3p.carclarity.com.au/marketing/lm/insert-lead/only-map', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                vrgId: data.id,
                lmRawLeadData: {
                  Lead: leadMarketLead,
                },
              }),
            }).then((response) => response.json()).then((data) => {
              datadogLogs.logger.info('Outcomes API Response', data);
              setFormSubmitted(true);
              setIsLoading(false);
            })
          } catch (error) {
            // ** Send error to GTM dataLayer
            window.dataLayer.push({
              event: 'formSubmitError',
              error: error,
            });
            // ** Send error to datadog RUM
            datadogRum.addError(error);
          }


        }
      })
      .catch((error) => {
        setIsLoading(false);
        // ** Send error to GTM dataLayer
        window.dataLayer.push({
          event: 'formSubmitError',
          error: error,
        });
        // ** Send error to datadog RUM
        datadogRum.addError(error);
        setApiErrorMessage(error);
        setFormSubmittedError(true);
      });
  };

  const sectionTitle = {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '19px',
    color: '#303030',
  };
  const label = {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    color: '#303030',
  };

  const inputNotTouched = {
    fontSize: '14px',
    borderColor: '#E8E8E8',
  };

  const inputTouched = {
    fontSize: '14px',
    borderColor: partnerBrandPrimary,
  };

  const inputError = {
    fontSize: '14px',
    borderColor: partnerBrandError,
  };

  const selectInputNotTouched = {
    fontSize: '14px',
    borderColor: '#E8E8E8',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
    backgroundPosition: `right 15px center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `25px`,
    paddingRight: `15px`,
    alignItems: 'center',
  };

  const selectInputTouched = {
    fontSize: '14px',
    borderColor: partnerBrandPrimary,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
    backgroundPosition: `right 15px center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `25px`,
    paddingRight: `15px`,
    alignItems: 'center',
  };

  const selectInputError = {
    fontSize: '14px',
    borderColor: partnerBrandError,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23004E93' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
    backgroundPosition: `right 15px center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `25px`,
    paddingRight: `15px`,
    alignItems: 'center',
  };

  const errorText = {
    fontWeight: '400',
    fontFamily: 'Avenir, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: partnerBrandError,
  };
  const checkboxErrorText = {
    fontWeight: '400',
    fontFamily: 'Avenir, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: partnerBrandError,
    paddingTop: '8px',
    marginTop: '8px',
  };

  function showApiErrorToast() {
    return (
      <>
        <div className='fixed flex justify-center items-center w-full p-4 top-5'>
          <div className='max-w-xs bg-white border-2 border-partnerBrandError rounded-md shadow-lg'>
            <div className='flex p-4'>
              <div className='ml-3'>
                <p className='text-sm text-gray-700'>{apiErrorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function formSubText() {
    if (process.env.REACT_APP_PARTNER_FORM_SUBTEXT !== '') {
      return (
        <h3 className='text-sm font-light text-center px-6 pb-6'>
          {process.env.REACT_APP_PARTNER_FORM_SUBTEXT}
        </h3>
      );
    }
  }

  if (!formSubmitted) {
    datadogRum.startView({
      name: 'Express Form',
    });

    return (
      <>
        {isLoading ? (
          <RouletteSpinnerOverlay
            loading={isLoading}
            color={partnerBrandPrimary}
          />
        ) : null}
        {/* <div className="flex flex-col items-center mx-auto" style={{ gap: "24px", padding: "48px 20px"}}>
            <div className="flex flex-col items-center w-full" style={{gap: "8px"}}>
                <h1 style={{fontWeight: "600", fontSize: "20px", lineHeight: "27px"}} className="text-center font-avenir">Express Application</h1>
            </div>

        </div> */}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="flex flex-col justify-center max-w-md lg:w-1/3 w-full px-5 lg:px-0 mx-auto" style={{ gap: "24px"}}> */}
            <div className='flex flex-col  justify-center' style={{}}>
              <h1
                style={{}}
                className='text-center font-avenir font-medium md:font-semibold text-xl pb-2'
              >
                Express Application
              </h1>
              {formSubText()}
              <div
                id='formStart'
                className='flex flex-col w-full items-start'
                style={{ gap: '24px', padding: '0' }}
              >
                {/* <h2 style={sectionTitle} className="font-avenir">Personal details</h2> */}
                <div
                  className='flex flex-col w-full items-start'
                  style={{ gap: '8px', padding: '0' }}
                >
                  <label className='block font-light text-xs' htmlFor='firstName'>
                    First name
                  </label>
                  <input
                    id='firstName'
                    maxLength={20}
                    style={
                      isTouched(firstName)
                        ? inputTouched
                        : errors?.firstName?.message
                          ? inputError
                          : inputNotTouched
                    }
                    className='text-center block w-full bg-transparent border-1 border-grey-200 rounded-full without-ring'
                    type='text'
                    placeholder=''
                    {...register('firstName', {
                      required: 'Please enter your first name',
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={({ message }) => <p style={errorText}>{message}</p>}
                  />
                </div>

                <div
                  className='flex flex-col w-full items-start'
                  style={{ gap: '8px', padding: '0' }}
                >
                  <label className='block font-light text-xs' htmlFor='lastName'>
                    Last name
                  </label>
                  <input
                    id='lastName'
                    maxLength={20}
                    style={
                      isTouched(lastName)
                        ? inputTouched
                        : errors?.lastName?.message
                          ? inputError
                          : inputNotTouched
                    }
                    className='text-center block w-full bg-transparent border-1 rounded-full without-ring'
                    type='text'
                    placeholder=''
                    {...register('lastName', {
                      required: 'Please enter your last name',
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={({ message }) => <p style={errorText}>{message}</p>}
                  />
                </div>

                <div
                  className='flex flex-col w-full items-start'
                  style={{ gap: '8px', padding: '0' }}
                >
                  <label className='block font-light text-xs' htmlFor='email'>
                    Email
                  </label>
                  <input
                    id='email'
                    style={
                      isTouched(email)
                        ? inputTouched
                        : errors?.email?.message
                          ? inputError
                          : inputNotTouched
                    }
                    className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300'
                    type='email'
                    placeholder='yourname@domain.com'
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message:
                          'That doesn’t look right. Please enter your email address in the correct format.',
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='email'
                    render={({ message }) => <p style={errorText}>{message}</p>}
                  />
                </div>

                <div
                  className='flex flex-col w-full items-start'
                  style={{ gap: '8px', padding: '0' }}
                >
                  <label className='block font-light text-xs' htmlFor='phoneNumber'>
                    Mobile
                  </label>
                  <input
                    id='phoneNumber'
                    // pattern='^04[0-9]{8}'
                    pattern='^(61)?4[0-9]{9}'
                    maxLength={11}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    inputMode='numeric'
                    style={
                      isTouched(phoneNumber)
                        ? inputTouched
                        : errors?.phoneNumber?.message
                          ? inputError
                          : inputNotTouched
                    }
                    className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300 '
                    type='tel'
                    placeholder='04xx xxx xxx'
                    onKeyDown={(event) => {
                      if (
                        !/[0-9]|ArrowLeft|ArrowRight|Backspace|Delete|Tab/.test(
                          event.key
                        )
                      ) {
                        event.preventDefault();
                      }
                    }}
                    {...register('phoneNumber', {
                      required: 'Please enter your Australian mobile number',
                      maxLength: 11,
                      pattern: {
                        // value: /^04[0-9]{8}/,
                        value: /^((\+61|61|0)[4])([ -]?[0-9]){8}$/,
                        message:
                          'Entered Australian mobile number format is incorrect. Re-enter it and try again',
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='phoneNumber'
                    render={({ message }) => <p style={errorText}>{message}</p>}
                  />
                </div>

                {/* <div className="flex flex-col w-full items-start" style={{ gap: "8px", padding:"0"}}>
                    <label style={{fontSize: "12px", fontWeight: "500", color: "#303030"}}className="block" htmlFor="state">State</label>
                    <select required defaultValue={''} id="state" style={isTouched(state)? selectInputTouched : errors?.businessStructure?.message ? selectInputError : selectInputNotTouched} className="text-center block w-full bg-transparent border-1 rounded-full without-ring " placeholder="Business Structure" {...register("state", { required: "Please select your state"})} >
                        <option hidden disabled value=''>Select</option>
                        <option value="NSW">NSW</option>
                        <option value="VIC">VIC</option>
                        <option value="QLD">QLD</option>
                        <option value="WA">WA</option>
                        <option value="SA">SA</option>
                        <option value="TAS">TAS</option>
                        <option value="ACT">ACT</option>
                        <option value="NT">NT</option>
                    </select>
                    <ErrorMessage errors={errors} name="state" render={({ message }) => <p style={errorText}>{message}</p>} />
                </div> */}

                <div
                  className='flex flex-col w-full items-start'
                  style={{ gap: '24px', padding: '0' }}
                >
                  {/* <h2 style={sectionTitle} className="font-avenir">Loan details</h2> */}
                  <div
                    className='flex flex-col w-full items-start'
                    style={{ gap: '8px', padding: '0' }}
                  >
                    <label className='block font-light text-xs' htmlFor='loanAmount'>
                      Loan amount <p style={{ display: 'inline' }} className='text-gray-300 font-light'> (Optional)</p>
                    </label>
                    <div className='relative w-full'>
                      <span className='absolute left-4 top-1/2 transform -translate-y-1/2'>
                        $
                      </span>
                      <input
                        id='loanAmount'
                        step={1}
                        maxLength={7}
                        onWheel={(e) => (e.target as HTMLElement).blur()}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          if (input.value.length > 7) {
                            input.value = input.value.slice(0, 7);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (
                            !/[0-9]|ArrowLeft|ArrowRight|Backspace|Delete|Tab/.test(
                              event.key
                            )
                          ) {
                            event.preventDefault();
                          }
                        }}
                        inputMode='numeric'
                        style={
                          isTouched(loanAmount)
                            ? inputTouched
                            : errors?.loanAmount?.message
                              ? inputError
                              : inputNotTouched
                        }
                        className='text-center block w-full bg-transparent border-1 rounded-full without-ring placeholder-gray-300'
                        type='number'
                        placeholder='Amount'
                        {...register('loanAmount', { maxLength: 7 })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='loanAmount'
                        render={({ message }) => (
                          <p style={errorText}>{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <hr
                  style={{ backgroundColor: '#F2F2F2' }}
                  className='h-px w-full'
                />
              </div>

              <div className='flex flex-col w-full pt-6'>
                <div className='flex w-full '>
                  <input
                    type='checkbox'
                    className='w-5 h-5 text-partnerBrandPrimary border-partnerBrandPrimary border-2 mr-2 rounded without-ring'
                    placeholder='acceptTerms'
                    {...register('acceptTerms', {
                      required:
                        'To continue you need to agree with the CarClarity privacy policy, credit guide, privacy consent and electronic document delivery consent',
                    })}
                  />
                  <p
                    className='pl-2 font-avenir'
                    style={{ fontSize: '12px', color: '#303030' }}
                  >
                    I confirm that I have read and accepted CarClarity{' '}
                    <a
                      target='_blank'
                      href='https://www.carclarity.com.au/privacy-policy'
                      style={{
                        textDecoration: 'underline',
                        color: partnerBrandPrimary,
                      }}
                      rel='noreferrer'
                    >
                      privacy policy
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      href='https://carclarity.com.au/credit-guide'
                      style={{
                        textDecoration: 'underline',
                        color: partnerBrandPrimary,
                      }}
                      rel='noreferrer'
                    >
                      credit guide
                    </a>
                    ,{' '}
                    <a
                      target='_blank'
                      href='https://carclarity.com.au/privacy-act-consent-electronic-delivery'
                      style={{
                        textDecoration: 'underline',
                        color: partnerBrandPrimary,
                      }}
                      rel='noreferrer'
                    >
                      privacy consent
                    </a>{' '}
                    and{' '}
                    <a
                      target='_blank'
                      href='https://carclarity.com.au/electronic-document-delivery-consent'
                      style={{
                        textDecoration: 'underline',
                        color: partnerBrandPrimary,
                      }}
                      rel='noreferrer'
                    >
                      electronic document delivery consent
                    </a>
                  </p>
                </div>
                <ErrorMessage
                  errors={errors}
                  name='acceptTerms'
                  render={({ message }) => (
                    <p style={checkboxErrorText}>{message}</p>
                  )}
                />
                <div className='flex w-full mt-4 justify-center align-middle items-center'>
                  <button
                    onClick={handleSubmit(onSubmit, onError)}
                    data-dd-action-name='Click Submit'
                    className='text-white bg-partnerBrandPrimary font-avenir text-sm px-9 py-3 rounded-full'
                    style={{ fontWeight: '500' }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>

        {formSubmittedError ? showApiErrorToast() : null}
      </>
    );
  } else {
    // window.scrollTo(0, 0);
    datadogRum.startView({
      name: 'Express Form - Submitted',
    });

    return (
      <>
        <div
          className='flex flex-col items-center w-full mx-auto'
          style={{}}
        >
          <div
            className='flex flex-col items-center w-full'
            style={{ gap: '40px', padding: '0px' }}
          >
            <span className='bg-carclaritySuccess text-white text-sm font-semibold inline-flex items-center rounded-full w-24 h-24'>
              <svg
                className='w-24 h-24'
                viewBox='-4 -32 40 90'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.0496 25.5903C11.6591 25.9809 11.0259 25.9809 10.6354 25.5903L1.17097 16.1259C0.78045 15.7354 0.78045 15.1022 1.17097 14.7117L4.59337 11.2893C4.98428 10.8984 5.61822 10.8988 6.00858 11.2903L10.635 15.9299C11.0255 16.3215 11.6597 16.3218 12.0506 15.9305L27.555 0.410396C27.9455 0.019535 28.579 0.0193758 28.9696 0.41004L32.3927 3.83308C32.7832 4.2236 32.7832 4.85676 32.3927 5.24729L12.0496 25.5903Z'
                  fill='white'
                />
              </svg>
            </span>
            <div
              className='flex flex-col items-center w-full'
              style={{ gap: '16px', padding: '0px' }}
            >
              <h1
                style={{ fontWeight: '600' }}
                className='text-lg text-center font-avenir'
              >
                Thank you!
              </h1>
              <p className='text-sm text-center font-light font-Montserrat'>
                Your enquiry has been submitted. <br></br> One of our loan
                experts will contact you shortly.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ExpressForm;
